body {
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    margin: 10px 0;
  }
  
  input,
  select {
    padding: 8px;
    margin: 5px;
    width: 200px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .book-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    text-align: center;
  }
  
  .book-item img {
    max-width: 100px;
    margin-bottom: 10px;
  }
  